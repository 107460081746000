import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/newTeam.jpeg";
import AddImage2 from "../../assets/img/add/use12.jpeg";
import AddImage3 from "../../assets/img/add/use10.jpeg";
import AddImage4 from "../../assets/img/add/try22.jpg";

//import DeployIcon from "../../assets/svg/Services/deploy.png";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
            <p className="font13">
              Our ISO certifications 9001:2015 and 45001:2018 proclaim our
              strong resolve to work and deliver to world standards with
              partners.
              <br />
              Be sure you are in good hands, with Omnicom in charge
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Network Engineering & Deployment"
                subtitle="Radio Network Planning, Traffic back haul - Microwave, Satellite and Fibre Optic backbone network design and deployment. Our experienced team ensures you meet your technical and project deployment targets. Our access deployments cover Radio (2G, 3G, 4G, 5G, WiFi) and Fibre Optics - FTTX"
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Network Performance & Management"
                subtitle="Using a wide array of cutting edge tools, our highly qualified engineers test and analyze your network's capabilities and performance. Our network performance optimization services cuts across the telecom network, from the access to the core network to ensure peak Quality of service to your customers."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Telecom Consulting & System Integration"
                subtitle="Having robust experience designing, deploying and managing performance across technologies positions our team to support and advice our clients, helping them to integrate different products from multiple vendors into a seamless and efficient solution."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="printer"
                title="Software & Cloud Solutions"
                subtitle="Based our deep experience, we deploy industry acclaimed applications or develop custom software applications to support or extend your network capabilities, automate processes and increase the efficiency of your workforce. Whether local or cloud based deployments, our experts stay ready in support to ensure peak performance for your teams."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="renew"
                title="Power Systems & Energies"
                subtitle="Our renewable energies solutions range from modular Kilowatt to Megawatts capacity solar farms. Designing to meet your specific circumstances, our hybrid solutions allow you to take full advantage of the mix of conventional power to renewable power sources."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">A few words about Us</h4>
                <h2 className="font40 extraBold">Omnicom TEAM</h2>
                <p className="font12">
                  From inception and for all projects, we remain proud of the
                  unique input of each of our qualified and highly motivated
                  executives whose decades of collective experience in the
                  telecom industry is the bedrock of our achievements.
                </p>
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton
                      title="Contact Us"
                      action={() => alert("clicked")}
                    />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img
                        src={AddImage2}
                        alt="office"
                        style={{ width: "221px", height: "333px" }}
                      />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img
                        src={AddImage3}
                        style={{ objectFit: "cover" }}
                        alt="office"
                      />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img
                        src={AddImage4}
                        alt="office"
                        style={{
                          objectFit: "cover",
                          width: "350px",
                          height: "280px",
                        }}
                      />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

// const Image = styled.img`
// height: 100px;`