import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

// Assets

import Huawei from "../../assets/img/clients/huawei+.svg";
import NineMobile from "../../assets/img/clients/9mobile.svg";
import Nokia from "../../assets/img/clients/nokia.svg";
import Mobile from "../../assets/img/clients/mtn.svg";
import Ceragon from "../../assets/img/clients/ceragon.svg";
import Glo from "../../assets/img/clients/Glo.svg";
import ZTE from "../../assets/img/clients/ihs.svg";
import Airtel from "../../assets/img/clients/Airtel.svg";
import Alcatel from "../../assets/img/clients/alcatel.svg";

export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Huawei} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={NineMobile} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Nokia} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Mobile} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Ceragon} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Glo} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ZTE} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Airtel} alt="client logo" />
        </LogoWrapper>
        {/* <LogoWrapper className="flexCenter">
          <ImgStyle src={Mobile} alt="client logo" />
        </LogoWrapper> */}
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Alcatel} alt="client logo" />
        </LogoWrapper>
       
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;