import React from "react";
import styled from "styled-components";
// Assets
const image1 = require("../../assets/svg/Services/net.svg")
const image2 = require("../../assets/svg/Services/optimum.png")
const image3 = require("../../assets/svg/Services/consult.png")
const image4 = require("../../assets/svg/Services/software.png")
const image5 = require("../../assets/svg/Services/renew.png")




export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = image1
      break;
    case "monitor":
      getIcon = image2
      break;
    case "browser":
      getIcon = image3
      break;
    case "printer":
      getIcon = image4
      break;
    case "renew":
      getIcon = image5
      break;
    default:
      getIcon = image5;
      break;
  }



  return (
    <Wrapper className="flex flexColumn">
      <Image src={getIcon} />
      
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>


    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
// const IconStyle = styled.div`
//   @media (max-width: 860px) {
//     margin: 0 auto;
//   }
// `;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

const Image = styled.img`
height: 70px;
width: 70px;
color : #4EC23D
`