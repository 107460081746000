import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Stories</h1>
            <p className="font13"></p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Why Choose Us!"
                text="Omnicom's competitive advantage lies in our track record of Affordability, Prompt delivery,
                 Flexible payment terms and 99.9% uptime of products and services, as well as reduction in the need to stockpile spares."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Our Core Values!"
                text="We are guided by our core values which includes; Responsibility, Integrity, Professionalism and  Innovation."
                //tag="HR, Omnicom"
                //author="Ms. Damilola Ayeni"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Our Vision!"
                text="We are driven by Our VISION to be a global brand revered for offering world class experential
                services to the telecommunications industry with state of the art 
                technology that guarantees efficiency in network design, maximum uptime and on-the-spot
                diagnosis and intervention."
                //tag="Project Director, Omnicom"
                //author="Eng. Kareem Oladigbo,"
              />
            </div>
          </div>
          {/* <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Our Philosophy!"
                text="At Omnicom, Our Philosophy is embracing the art of understanding and identifying the culture, constraints
                and unique needs of each customer as the key to unlocking the best ways
                to optimize, streamline and boost their network and their business for 
                profitability and sustainability"
                tag="Finance, Omnicom"
                author="Mr. Olabode Isiaq"
                
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Training and Re-Training!"
                text="Lorem ipsum dolor sit amet, consetetur sadi"
                tag="Head, NPO"
                author="Ms. Vivian Okoye "
                
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Our Clients, Our World!"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.
                ksfisfie jiufoiew jjhfiuweife jhjhfijows jskfjewofi ojhi"
                tag="Head, Technical Equipment"
                author="Mr. Chuks Utubor"
                action={() => alert("clicked")}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Milestones</h1>
            <p className="font13"></p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;